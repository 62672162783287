<template>
    <div class="quiz-container">
        <div class="quiz-content">
            <el-breadcrumb separator-class="el-icon-arrow-right" v-if="role === 3">
                <el-breadcrumb-item>
                    <span class="color-blue" @click="goBackThree">班级管理</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                    <span class="color-blue" @click="goBackTwo">{{className}}</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                    <span class="color-blue" @click="goBack">讨论列表</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>{{course_name}}</el-breadcrumb-item>
            </el-breadcrumb>
            <el-breadcrumb separator-class="el-icon-arrow-right" v-else>
                <el-breadcrumb-item>
                    <span class="color-blue" @click="goBackTwo">{{className}}</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                    <span class="color-blue" @click="goBack">讨论列表</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>{{course_name}}</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="quiz-list">
                <div class="discuss-title">讨论</div>
                <el-scrollbar class="discuss-detail" style="height: 100%" :native="false">
                    <div v-if="discussList.length === 0" style="text-align: center; padding: 20px; color: #666;">暂无讨论内容</div>
                    <div class="item" v-for="(discussItem, discussIndex) in discussList">
                        <div class="item-left">
                            <img :src="discussItem.ico" alt="">
                        </div>
                        <div class="item-right">
                            <div class="item-name text-overflow">
                                <span class="tags" v-if="discussItem.send_role === 3"></span>{{discussItem.send_name}}
                            </div>
                            <div class="item-content" v-html="discussItem.content" @click="addComment($event)"></div>
                        </div>
                    </div>
                </el-scrollbar>
                <el-pagination class="pages-center" background style="margin: 20px 0;"
                               :current-page.sync="discussPages.currentPageNum"
                               :page-size="discussPages.eachPageNum"
                               :total="discussPages.total"
                               layout="prev, pager, next, jumper"
                               @current-change="discussCurrentChange">
                </el-pagination>
            </div>
        </div>
        <el-dialog :visible.sync="imgPreviewDialog" :width="imgPreviewWidth" class="img-preview-dialog">
            <img :src="imgPreview" alt="" class="img-preview">
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                c_id: Number(this.$route.query.c_id ) || '',
                className: this.$route.query.className || '',
                course_name: this.$route.query.course_name || '',
                course_record_id: Number(this.$route.query.course_record_id ) || '',
                discussList: [],
                discussPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                role: Number(localStorage.getItem('role')),
                imgPreviewDialog: false,
                imgPreview: '',
                imgPreviewWidth: '300px',
                imgWidth: 0,
                imgHeight: 0,
            }
        },
        mounted() {
            this.getDiscussList();
            this.setImgPreviewWidth();
        },
        methods: {
            addComment(event) {
                if (event.target.nodeName === 'IMG') {
                    this.imgPreview = event.path[0].currentSrc;
                    this.imgWidth = event.path[0].naturalWidth
                    this.imgHeight = event.path[0].naturalHeight
                    this.setImgPreviewWidth();
                    this.imgPreviewDialog = true;
                }
            },
            setImgPreviewWidth() {
                let screenWidth = document.body.clientWidth;
                if (this.imgWidth > 1500) {
                    this.imgPreviewWidth = '80%'
                } else {
                    this.imgPreviewWidth = this.imgWidth + 40 + 'px'
                }
            },
            getDiscussList() {
                let params = {
                    c_id: this.c_id,
                    course_record_id: this.course_record_id,
                    page: this.discussPages.currentPageNum,
                    limit: this.discussPages.eachPageNum,
                }
                this.$shttp.axiosGetBy(this.$api.showDiscussNums, params, (res) => {
                    if (res.code === 200) {
                        this.discussList = res.data.data;
                        this.discussPages.total = res.data.total
                    }
                })
            },
            discussCurrentChange(val) {
                this.discussPages.currentPageNum = val;
                this.getDiscussList();
            },
            goBack() {
                this.$router.go(-1)
            },
            goBackTwo() {
                this.$router.go(-2)
            },
            goBackThree() {
                this.$router.go(-3)
            },
        }
    }
</script>

<style scoped lang="scss">
    .img-preview-dialog {
        .img-preview {
            display: block;
            max-width: 100%;
        }
    }
    ::v-deep .el-breadcrumb {
        .el-breadcrumb__inner {
            color: #000;
        }
    }
    .quiz-container {
        width: 100%;
        height: calc(100% - 40px);
        padding: 20px 0;
        .quiz-content {
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            .quiz-list {
                display: flex;
                flex-direction: column;
                height: 1%;
                flex: 1;
                background: #fff;
                margin-top: 20px;
                .discuss-title {
                    height: 50px;
                    line-height: 50px;
                    border-bottom: 1px solid #EAEAEA;
                    padding: 0 20px;
                }
                .discuss-detail {
                    height: 1%;
                    flex: 1;
                    ::v-deep .el-scrollbar__wrap {
                        overflow-x: hidden;
                    }
                    .item {
                        display: flex;
                        padding: 10px 20px;
                        border-bottom: 1px solid #EAEAEA;
                        .item-left {
                            width: 40px;
                            height: 40px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            border: 1px solid #EAEAEA;
                            overflow: hidden;
                            img {
                                max-height: 100%;
                                max-width: 100%;
                            }
                        }
                        .item-right {
                            margin-left: 10px;
                            width: 1%;
                            flex: 1;
                            .item-name {
                                line-height: 19px;
                                color: #666;
                            }
                            .item-content {
                                line-height: 19px;
                                margin-top: 4px;
                                ::v-deep img {
                                    display: block;
                                    max-width: 300px;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>